import { useTranslate } from "@pankod/refine-core"

export const DeviceStatusIndicator = ({ status }: { status: string }) => {
  let outerStyle: string
  let innerColor: string
  let text: string
  const translate = useTranslate()

  switch (status) {
    case "active":
      outerStyle = "bg-emerald-50 ring-2 ring-emerald-100"
      innerColor = "text-emerald-600"
      text = translate("pages.devices.active")
      break
    case "inactive":
      outerStyle = "bg-amber-50 ring-2 ring-amber-100"
      innerColor = "text-amber-400"
      text = translate("pages.devices.inactive")
      break
    default:
      outerStyle = "bg-red-50 ring-2 ring-red-100"
      innerColor = "text-red-600"
      text = translate("pages.devices.notWorking")
  }

  return (
    <div className="flex-shrink-0">
      <div
        className={
          `px-2 flex items-center justify-between h-8 rounded-full ` +
          outerStyle
        }
      >
        <span className="mr-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            fill="currentColor"
            className={`w-4 h-4 ` + innerColor}
          >
            <circle cx="16" cy="16" r="8"></circle>
          </svg>
        </span>
        <span className="font-medium">{text}</span>
      </div>
    </div>
  )
}
