import { useMemo } from "react"
import { useCustom, useOne, useTranslate } from "@pankod/refine-core"
import { IRoles, IUserInfo } from "interfaces"
import { SyLabel, SySelect, SySelectOption } from "components/new/shared"
import { getRoleDisplayName } from "utilities/role"

export const RoleDropdown = ({
  roleId,
  onRoleIdChange,
  userId
}: {
  roleId?: string
  onRoleIdChange: (roleId: string | undefined) => void
  userId?: number
}) => {
  const translate = useTranslate()

  const { data: roleData } = useCustom<[IRoles]>({
    url: "roles",
    method: "get"
  })

  const { data: userData } = useOne<IUserInfo>({
    resource: "users",
    id: userId ?? 0
  })

  const userTypeName = userData?.data.user_type_name

  const roleOptions: readonly SySelectOption<string, string>[] = useMemo(() => {
    if (!userTypeName) {
      return []
    }

    const roles: readonly IRoles[] = roleData?.data ?? []

    const formatRole = (role: IRoles): SySelectOption<string, string> => ({
      value: role.id.toFixed(),
      data: getRoleDisplayName(role.name, translate)
    })

    const filteredRoles = roles.filter((role) =>
      userTypeName === "OrganizationUser"
        ? role.name === "OrganizationOwner"
        : role.name !== "OrganizationOwner"
    )

    return filteredRoles.map(formatRole)
  }, [roleData, translate, userTypeName])

  return (
    <div>
      <SyLabel required={true}>{translate("pages.users.role")}</SyLabel>
      <SySelect
        id="roleId"
        placeholder={translate("pages.users.selectRole")}
        options={roleOptions}
        value={roleId}
        onValueChange={onRoleIdChange}
      />
    </div>
  )
}
