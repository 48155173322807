import roomsDisplayImg from "assets/images/rooms_display.png"
import kioskDisplayImg from "assets/images/kiosk_display.png"
import ql700 from "assets/images/ql700.png"

export const DeviceImage = ({ type }: { type: string }) => {
  let imgSrc: string

  switch (type) {
    case "Kiosk":
      imgSrc = kioskDisplayImg
      break
    case "Room Display":
      imgSrc = roomsDisplayImg
      break
    case "Printer":
      imgSrc = ql700
      break
    default:
      imgSrc = roomsDisplayImg
      break
  }

  return (
    <img
      src={imgSrc}
      alt="deviceModel"
      className="flex items-center justify-center max-h-24 object-scale-down"
    />
  )
}
