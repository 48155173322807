import {
  IResourceComponentsProps,
  usePermissions,
  useQueryClient,
  useTranslate
} from "@pankod/refine-core"
import React, { useCallback, useState } from "react"

import { IUserInfo } from "interfaces"
import { EditIcon, DeleteIcon } from "components/icons"
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel
} from "@tanstack/react-table"
import { useModalForm } from "@pankod/refine-react-hook-form"
import { Table } from "components/table/table"
import { useLocalStorage } from "hooks/useLocalStorage"
import { LOG, Logger } from "utilities/logger"
import { usePagination } from "hooks/usePagination"
import { CreateUser } from "./create"
import { EditUser } from "./edit"
import { DeleteUser } from "./delete"
import { INF_SCROLL_QUERY_KEY_TYPES, LOCAL_STORAGE_KEYS } from "utilities/types"
import { Button } from "components/globals/Button"
import {
  faEllipsisVertical,
  faPlus,
  faUpload
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "@headlessui/react"
import { SyButton } from "components/new/shared"
import { ImportUsersCsvModal } from "components/users/ImportUsersCsvModal"
import { getRoleDisplayName } from "utilities/role"
import { UserIcon } from "@heroicons/react/24/outline"

export const UsersList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate()
  const queryClient = useQueryClient()

  const [selectedUserId, setSelectedUserId] = useState(0)

  const [columnVisibility, setLocalStorage]: [any, (arg0: any) => void] =
    useLocalStorage(LOCAL_STORAGE_KEYS.USERS, {})

  const { data: permissionsData } = usePermissions<string>()
  const columnArray: ColumnDef<IUserInfo>[] = [
    {
      id: "user",
      header: "Name",
      accessorKey: "picture",
      cell: ({ row }) => {
        const {
          picture,
          person: { first_name, last_name }
        } = row.original
        let notFound = false

        if (first_name === undefined || last_name === undefined) notFound = true

        return (
          <div className="flex items-center">
            {picture ? (
              <img
                alt="userpic"
                width="40"
                height="40"
                className="rounded-full object-cover aspect-square"
                src={picture}
              />
            ) : (
              <UserIcon className="h-8 w-8 text-systam-blue" />
            )}
            <div className="pl-2">
              <p className={notFound ? "text-gray-500" : ""}>
                {notFound
                  ? translate("users.userNotFound")
                  : `${first_name} ${last_name}`}
              </p>
            </div>
          </div>
        )
      }
    },
    {
      id: "organization_name",
      header: "Organization",
      accessorKey: "person.organization_name"
    },
    {
      id: "user_email",
      header: "Email",
      accessorKey: "person.email"
    },
    {
      id: "user_phone",
      header: "Phone",
      accessorKey: "person.phone"
    },
    {
      id: "user_role",
      header: "Role",
      accessorKey: "role",
      cell: ({ row }) => {
        const { role } = row.original
        return (
          <div className="flex items-center">
            <p>{getRoleDisplayName(role.name, translate)}</p>
          </div>
        )
      }
    }
  ]

  const [importUserCsvOpen, setImportUserCsvOpen] = useState(false)

  const isEditCreateButton =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("All.ReadWrite") ||
    permissionsData?.includes("User.ReadWrite") ||
    permissionsData?.includes("User.All")
  const isDeleteButton =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("User.All")

  function getActionButtons(id: number) {
    return (
      <Menu as="div" className="relative inline-flex text-left">
        <Menu.Button
          className="rounded-full w-10 h-10 border border-gray-200 p-2 text-lg font-medium transition duration-150 ease-in-out !bg-blue-100 hover:!bg-blue-300 hover:text-white"
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            className="align-center self-center"
          />
        </Menu.Button>
        <Menu.Items className="absolute z-50 right-0 mt-11 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">
            {isEditCreateButton && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      void Logger().log(LOG.PRESS_EDIT_USER_MODAL)
                      showEditModal(id)
                      setSelectedUserId(id)
                    }}
                    className={`${
                      active
                        ? "font-medium bg-systam-blue text-white"
                        : "bg-white text-gray-600"
                    }  flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                  >
                    {EditIcon}
                    <p className="ml-2">{translate("pages.users.editUser")}</p>
                  </button>
                )}
              </Menu.Item>
            )}
            {isDeleteButton && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      void Logger().log(LOG.PRESS_DEL_USER_MODAL)
                      setSelectedUserId(id)
                      showDeleteModal(id)
                    }}
                    className={`${
                      active
                        ? "font-medium bg-systam-blue text-white"
                        : "bg-white text-gray-600"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                  >
                    {DeleteIcon}
                    <p className="ml-2">
                      {translate("pages.users.deleteUser")}
                    </p>
                  </button>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Menu>
    )
  }

  if (isEditCreateButton || isDeleteButton) {
    columnArray.push({
      id: "action",
      header: "Action",
      accessorKey: "id",
      cell: function render({ getValue }) {
        const id = getValue() as number
        if (isEditCreateButton || isDeleteButton) {
          return getActionButtons(id)
        }
      }
    })
  }

  const columns = React.useMemo<ColumnDef<IUserInfo | any>[]>(
    () => columnArray,
    [permissionsData]
  )

  const [users, page, isLoading, fetchNextPage, hasNextPage, isFetching] =
    usePagination<IUserInfo>(`/users`, "", "", INF_SCROLL_QUERY_KEY_TYPES.USERS)

  const { getHeaderGroups, getRowModel, getAllColumns } = useReactTable({
    onColumnVisibilityChange: (state) => {
      setLocalStorage(state)
    },
    initialState: {},
    state: {
      columnVisibility
    },
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableHiding: true
  })

  const createModalFormReturnValues = useModalForm({
    refineCoreProps: {
      action: "create",
      resource: `/users`
    }
  })

  const editModalFormReturnValues = useModalForm({
    refineCoreProps: {
      action: "edit",
      resource: `/users`
    }
  })

  const deleteModalFormReturnValues = useModalForm({
    refineCoreProps: {
      resource: `/users`
    }
  })

  const {
    modal: { show: showCreateModal }
  } = createModalFormReturnValues

  const {
    modal: { show: showEditModal }
  } = editModalFormReturnValues

  const {
    modal: { show: showDeleteModal }
  } = deleteModalFormReturnValues

  const handleCreateUser = useCallback(() => {
    void Logger().log(LOG.PRESS_CREATE_USER_MODAL)
    showCreateModal()
  }, [showCreateModal])

  const CreateUserButton = () => {
    return (
      <div className="flex justify-end items-center">
        {isEditCreateButton && (
          <Button
            icon={faPlus}
            name={translate("pages.users.createUser")}
            onClick={handleCreateUser}
          />
        )}
      </div>
    )
  }

  return (
    <div className="container mx-auto pb-4">
      <CreateUser {...createModalFormReturnValues} />
      <EditUser {...editModalFormReturnValues} userId={selectedUserId} />
      <DeleteUser
        {...deleteModalFormReturnValues}
        selectedId={selectedUserId}
      />
      <div>
        <Table
          header="Users"
          page={page}
          getAllColumns={getAllColumns}
          getHeaderGroups={getHeaderGroups}
          getRowModel={getRowModel}
          leftButtons={[<CreateUserButton />]}
          rightButtons={[
            <SyButton
              variant="outlined"
              color="secondary"
              label={translate("buttons.importCsv")}
              rightIcon={faUpload}
              onClick={() => {
                setImportUserCsvOpen(true)
                void Logger().log(LOG.PRESS_IMPORT_USER_BTN)
              }}
            />
          ]}
          isLoading={isLoading}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      </div>
      <ImportUsersCsvModal
        open={importUserCsvOpen}
        onSuccess={() => {
          setImportUserCsvOpen(false)
          void queryClient.invalidateQueries([INF_SCROLL_QUERY_KEY_TYPES.USERS])
        }}
        onClose={() => {
          setImportUserCsvOpen(false)
        }}
      />
    </div>
  )
}
