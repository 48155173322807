import React, { useEffect, useState } from "react"
import { axiosInstance, axiosNoUrl } from "utilities/dataProvider"
import {
  IResourceComponentsProps,
  useTranslate,
  useApiUrl,
  useRouterContext
} from "@pankod/refine-core"
import { IDevice, VersionInfo } from "interfaces"
import { useQuery } from "@tanstack/react-query"
import { DeviceTypeDropdown } from "components/dropdown/DeviceTypeDropdown"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { InputSearch } from "components/globals/InputSearch"
import { Button } from "components/globals/Button"
import { useModalForm } from "@pankod/refine-react-hook-form"
import { CreateDevice } from "./create"
import { setDeviceSearch } from "reduxStore/reducers/deviceSearchReducer"
import { useAppSelector } from "reduxStore/store"
import { useDispatch } from "react-redux"
import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons"
import { LOG, Logger } from "utilities/logger"
import { USER_ID_KEY } from "utilities/constants"
import { useLinkedPrinters } from "hooks/useLinkedPrinters"
import { DeviceContainer } from "./components/DeviceContainer"

interface Props {
  isRooms: boolean
}

/// Lists all devices for Visit
export const VisitDeviceList: React.FC<IResourceComponentsProps> = () => {
  return <DeviceList isRooms={false} />
}

/// Used to list devices.
/// isRooms defines whether to show Rooms or Visit devices
export const DeviceList: React.FC<Props> = ({ isRooms }) => {
  const translate = useTranslate()

  const [selectedType, setSelectedType] = useState<string>("")
  const [search, setSearch] = useState<string>("")
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  const deviceSearch = useAppSelector<string>(
    (state) => state.deviceSearch.deviceSearchInput
  )
  const dispatch = useDispatch()

  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  const apiUrl = useApiUrl()

  // Initially set search to deviceSearch from redux
  useEffect(() => {
    setSearch(deviceSearch)
  }, [])

  // Update redux deviceSearch on search change
  useEffect(() => {
    dispatch(setDeviceSearch(search))
  }, [search])

  const { data: deviceData, isLoading } = useQuery<[IDevice]>(
    ["devicelist"],
    async () => {
      const endpoint = isRooms
        ? "devices/systam-workplace"
        : "devices/systam-visit"
      const res = await axiosInstance.get(`${apiUrl}/${endpoint}`)
      return res.data
    }
  )

  const { linkedPrintersData } = useLinkedPrinters()

  function filterWorkspace(device: IDevice) {
    return (
      selectedWorkspaceId &&
      device.workspace_id === parseInt(selectedWorkspaceId)
    )
  }

  function filterSelection(device: IDevice) {
    if (selectedType === "Show all") {
      return device
    } else if (selectedType !== undefined && selectedType.length > 0) {
      return device.model.type === selectedType
    } else {
      return device
    }
  }

  function filterSearch(device: IDevice) {
    if (deviceSearch.length > 0) {
      return device.name.toLowerCase().includes(deviceSearch.toLowerCase())
    } else {
      return device
    }
  }

  function getFilteredDevices() {
    if (deviceData !== undefined) {
      const deviceArray = deviceData
        .filter(filterWorkspace)
        .filter(filterSelection)
        .filter(filterSearch)
        .map((device: IDevice) => {
          // Check if the device is a Kiosk and has linked printers
          const isLinkedToDevice = linkedPrintersData.some(
            (printer) => printer.linkedToDeviceId === device.id
          )

          // Check if the device itself is a Printer and is linked to a Kiosk
          const isLinkedPrinter = linkedPrintersData.some(
            (printer) => printer.device_id === device.id // Correct the check to use device_id
          )

          return (
            <DeviceContainer
              key={device.id.toString()}
              id={device.id}
              device={device}
              isLinkedToDevice={isLinkedToDevice || isLinkedPrinter}
            />
          )
        })

      if (deviceArray != null && deviceArray.length > 0) {
        return deviceArray
      }
    }
    return <p>{translate("pages.devices.noDevices")}</p>
  }

  const createModalFormReturnValues = useModalForm({
    refineCoreProps: {
      action: "create",
      resource: `/devices`
    }
  })

  const {
    modal: { show: showCreateModal }
  } = createModalFormReturnValues

  const handleAddDevice = () => {
    showCreateModal()
  }

  const handleAddDisplay = () => {
    push(`/displays/create`)
  }

  return (
    <>
      <CreateDevice {...createModalFormReturnValues} />
      <h1 className="flex justify-between items-center font-kanit font-medium text-3xl h-20">
        {isRooms
          ? translate("pages.devices.displays")
          : translate("pages.devices.devices")}
      </h1>
      <div className="flex row items-end">
        {!isRooms && (
          <div className="max-w-3xl mr-4">
            <DeviceTypeDropdown
              value={selectedType}
              setState={setSelectedType}
            />
          </div>
        )}
        <div className="">
          <InputSearch
            search={search}
            setSearch={setSearch}
            placeholder={translate("buttons.filter")}
          />
        </div>
        <div className="ml-auto">
          <div className="flex flex-row gap-2">
            {isRooms && (
              <Button
                icon={faDownload}
                name={translate("buttons.downloadApk")}
                onClick={() => {
                  async function downloadApk() {
                    const getVersionInfo = async (): Promise<
                      VersionInfo | undefined
                    > => {
                      try {
                        const response = await axiosNoUrl.get<VersionInfo>(
                          "https://storage.googleapis.com/rooms-production/version-info.json"
                        )
                        const versionInfo = response.data
                        void Logger().log(
                          LOG.PRESS_DOWNLOAD_APK,
                          `UserId: ${
                            localStorage.getItem(USER_ID_KEY) ?? ""
                          } downloaded APK with Version: ${
                            versionInfo.version
                          }, Build: ${versionInfo.releaseDate}, Notes: ${
                            versionInfo.notes
                          }`
                        )
                        return versionInfo
                      } catch (error) {
                        console.error("Error fetching version info:", error)
                        return undefined
                      }
                    }

                    const version = await getVersionInfo()

                    return await axiosNoUrl
                      .get(
                        `https://storage.googleapis.com/rooms-production/app-release.apk`,
                        {
                          responseType: "blob"
                        }
                      )
                      .then((response) => {
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        )
                        const link = document.createElement("a")
                        link.href = url
                        link.setAttribute(
                          "download",
                          `${
                            version?.version
                              ? `rooms-${version?.version}`
                              : "systam-rooms"
                          }.apk`
                        )
                        document.body.appendChild(link)
                        link.click()
                      })
                  }
                  downloadApk().catch((error) => {
                    console.error("Error downloading APK:", error)
                  })
                }}
              />
            )}
            <Button
              icon={faPlus}
              name={translate("pages.devices.addDevice")}
              onClick={isRooms ? handleAddDisplay : handleAddDevice}
            />
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {isLoading && (
            <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
              <Skeleton count={2} height={324} width={270} />
              <Skeleton count={2} height={324} width={270} />
              <Skeleton count={2} height={324} width={270} />
              <Skeleton count={2} height={324} width={270} />
            </SkeletonTheme>
          )}
          {deviceData && getFilteredDevices()}
        </div>
      </div>
    </>
  )
}
