import {
  Authenticated,
  useApiUrl,
  useCustomMutation,
  useDelete,
  useQuery,
  useQueryClient,
  useRouterContext,
  useTranslate,
  useUpdate
} from "@pankod/refine-core"
import { useParams } from "@pankod/refine-react-router-v6"
import { CalendarProviderDropdown } from "components/dropdown/CalendarProviderDropdown"
import { Input } from "components/globals/Input"
import { InputSearch } from "components/globals/InputSearch"
import { TabButton } from "components/globals/TabButton"
import { SyModal } from "components/new/shared"
import { useCallback, useEffect, useState } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { SingleRoomResponse } from "types"
import { axiosInstance } from "utilities/dataProvider"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import linkIcon from "assets/images/link-icon.svg"
import philipsImg from "assets/images/philips_rooms_sml.png"
import { useAppDispatch } from "reduxStore/store"
import { DRAWER_MODES } from "components/drawer/DrawerWrapper"
import {
  setIsDrawerOpen,
  setMode,
  setSelectedResourceId
} from "reduxStore/reducers/drawerReducer"
import { UnlinkButton } from "./components/UnlinkButton"
import { BackButton } from "components/icons"

export const SpaceShow: React.FC = () => {
  const { spaceId } = useParams<"spaceId">()
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const { mutate } = useUpdate()
  const { mutate: deleteRoomMutation } = useDelete()
  const { mutate: deleteLinkMutation } = useCustomMutation()
  const apiUrl = useApiUrl()
  const dispatch = useAppDispatch()

  useEffect(() => {
    void queryClient.resetQueries({
      queryKey: [`space`]
    })
  }, [])

  const { data: spaceData, isLoading } = useQuery<SingleRoomResponse>(
    ["space"],
    async () => {
      const res = await axiosInstance.get(`rooms/${spaceId}`)
      return res.data
    }
  )

  const { account_id, address } = spaceData?.resources?.[0] || {}
  const [spaceName, setSpaceName] = useState(spaceData?.name ?? "")
  const [activeTab, setActiveTab] = useState("general")
  const [calendarAccountId, setCalendarAccountId] = useState(account_id ?? 0)
  const [calendarAddress, setCalendarAddress] = useState(address ?? "")
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [search, setSearch] = useState("")
  const [linkedDisplaysCount, setLinkedDisplaysCount] = useState(0)
  const [deleteLinkModalOpen, setDeleteLinkModalOpen] = useState(false)
  const [selectedLinkDeviceId, setSelectedLinkDeviceId] = useState<
    number | null
  >(null)

  const onSubmit = (values: object) => {
    if (spaceId) {
      mutate(
        {
          resource: `rooms`,
          id: spaceId,
          values
        },
        {
          onError: (error) => {
            customToast.error(
              translate("notifications.editError", {
                resource: translate("resources.space")
              })
            )
          },
          onSuccess: () => {
            customToast.success(
              translate("notifications.editSuccess", {
                resource: translate("resources.space")
              })
            )
          },
          onSettled: () => {
            void queryClient.resetQueries({
              queryKey: ["space"]
            })
          }
        }
      )
    }
  }

  const handleRemoveRoom = useCallback(() => {
    if (spaceId) {
      deleteRoomMutation(
        {
          id: spaceId,
          resource: `rooms`
        },
        {
          onSuccess: (_data, _variables, _context) => {
            void Logger().log(LOG.REMOVE_ROOM, `Space (ID ${spaceId}) deleted.`)
            customToast.success(
              translate("notifications.deleteSuccess", {
                resource: translate("resources.space")
              })
            )
            setDeleteModalOpen(false)
            push("../spaces")
          },
          onError: (_error, _variables, _context) => {
            void Logger().error(LOG.REMOVE_ROOM, `${_error.stack}`)
          },
          onSettled(data, error, variables, context) {
            setTimeout(() => void queryClient.invalidateQueries(), 100)
          }
        }
      )
    }
  }, [])

  const handleRemoveLink = useCallback((selectedId) => {
    if (selectedLinkDeviceId || selectedId) {
      deleteLinkMutation(
        {
          url: `${apiUrl}/devices/${selectedId}/room`,
          method: "delete",
          values: {}
        },
        {
          onSuccess: (_data, _variables, _context) => {
            void Logger().log(
              LOG.UNLINK_DEVICE,
              `Removed linked device (ID ${selectedId}) from room (ID ${spaceId}).`
            )
            customToast.success(
              translate("notifications.unlinkSuccess", {
                resource: translate("resources.space")
              })
            )
            setDeleteModalOpen(false)
          },
          onError: (_error, _variables, _context) => {
            void Logger().error(LOG.UNLINK_DEVICE, `${_error.stack}`)
          },
          onSettled(data, error, variables, context) {
            setTimeout(() => void queryClient.invalidateQueries(), 100)
            setDeleteLinkModalOpen(false)
          }
        }
      )
    }
  }, [])

  useEffect(() => {
    if (
      (calendarAddress === "" || calendarAddress !== address) &&
      address !== undefined &&
      address !== ""
    ) {
      setCalendarAddress(address)
    }

    if (calendarAccountId === 0 || calendarAccountId !== account_id) {
      setCalendarAccountId(account_id ?? 0)
    }

    if (
      (spaceName === "" || spaceName !== spaceData?.name) &&
      spaceData?.name
    ) {
      setSpaceName(spaceData?.name)
    }

    if (spaceData?.devices) {
      setLinkedDisplaysCount(spaceData.devices.length)
    }

    if (spaceData?.id) {
      dispatch(setSelectedResourceId(spaceData?.id))
    }
  }, [spaceData])

  function getFilteredDevices() {
    if (spaceData?.devices !== null) {
      const deviceArray = spaceData?.devices
        .filter(
          // Filter devices by search input
          (device) =>
            device.name &&
            (device.name as string).toLowerCase().includes(search.toLowerCase())
        )
        .map((device) => {
          return (
            <div
              className="flex flex-col justify-between gap-1 p-4 border rounded-lg ring-1 ring-one-gray-200 shadow-sm w-56"
              key={device.id}
            >
              <div className="flex flex-col">
                <div className="flex flex-col items-center w-full">
                  <img src={philipsImg} alt="rooms" className="my-2 w-32" />
                </div>
                <p className="font-medium text-wrap truncate">{device.name}</p>
                <p className="text-one-gray-600">Room Display</p>
              </div>
              <UnlinkButton
                onClick={() => {
                  if (device.id) {
                    setSelectedLinkDeviceId(device.id)
                    setDeleteLinkModalOpen(true)
                  }
                }}
              />
            </div>
          )
        })

      if (deviceArray != null && deviceArray.length > 0) {
        return deviceArray
      }
    }
    return <p>{translate("pages.devices.noDevices")}</p>
  }

  const showDrawer = useCallback(() => {
    dispatch(setIsDrawerOpen(true))
  }, [dispatch])

  const setDrawerMode = useCallback(
    (mode: string) => {
      dispatch(setMode(mode))
    },
    [dispatch]
  )

  return (
    <Authenticated>
      <div className="container mx-auto">
        <div className="mb-6 flex row h-12 items-center justify-between">
          <button
            onClick={() => {
              push("../spaces")
            }}
          >
            <BackButton />
          </button>
          <h1 className="text-3xl px-4 font-medium break-all truncate">
            {spaceData?.name}
          </h1>
          <div />
        </div>
        <div className="my-6 border-b border-gray-200">
          <div className="-mb-px flex space-x-8">
            <TabButton
              activeTab={activeTab}
              tabId="general"
              tabTitle={translate("pages.devices.tab.generalSettings")}
              setActiveTab={setActiveTab}
            />
            <TabButton
              activeTab={activeTab}
              tabId="links"
              tabTitle={translate("pages.devices.tab.linkedDisplays")}
              setActiveTab={setActiveTab}
              itemCount={linkedDisplaysCount}
            />
          </div>
        </div>
        {isLoading && (
          <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
            <div className="flex flex-row">
              <Skeleton
                className="mt-2 mr-2"
                count={1}
                width={384}
                height={400}
              />
            </div>
          </SkeletonTheme>
        )}
        {spaceData && (
          <div className="flex row gap-2">
            {activeTab === "general" && (
              <div className="flex flex-col gap-4">
                <div className="w-120 border rounded-xl shadow h-auto p-6">
                  <div className="flex-col">
                    <h2 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                      {translate("pages.devices.tab.generalSettings")}
                    </h2>
                    <Input
                      label={translate("form.name")}
                      id="input_name"
                      value={spaceName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSpaceName(e.target.value)
                      }
                    />
                    <CalendarProviderDropdown
                      value={calendarAccountId}
                      setState={setCalendarAccountId}
                    />
                    <div className="mt-4 flex flex-col">
                      <Input
                        disabled={calendarAccountId === 0}
                        label={translate(
                          "pages.devices.settings.calendarResource"
                        )}
                        id="input_address"
                        value={calendarAddress}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setCalendarAddress(e.target.value)
                        }
                      />
                      <p className="text-sm text-one-gray-500 mt-1">
                        {translate("form.resourceAddressDescription")}
                      </p>
                    </div>
                    <button
                      className="mt-6 px-4 py-2 rounded-lg bg-one-gray-900 text-white"
                      onClick={() => {
                        onSubmit({
                          name: spaceName,
                          resource_account_id:
                            calendarAccountId === 0 ? null : calendarAccountId,
                          resource_address:
                            calendarAccountId > 0 && calendarAddress !== ""
                              ? calendarAddress
                              : null
                        })
                      }}
                    >
                      {translate("buttons.apply")}
                    </button>
                  </div>
                </div>
                <div className="w-120 border rounded-xl shadow h-auto p-6">
                  <div className="flex-col">
                    <h2 className="text-lg font-medium leading-6 text-gray-900 mb-1">
                      {translate("pages.spaces.dangerZone")}
                    </h2>
                    <p className="text-sm text-one-gray-500">
                      {translate("pages.spaces.dangerZoneDescription")}
                    </p>
                    <button
                      onClick={() => {
                        // Open modal for deletion confirmation
                        setDeleteModalOpen(true)
                      }}
                      className="mt-4 ring-1 border rounded-lg border-one-rose-500 text-one-rose-500 px-4 py-2 font-medium"
                    >
                      {translate("pages.spaces.deleteSpace")}
                    </button>
                  </div>
                </div>
                <SyModal
                  open={deleteModalOpen}
                  onClose={() => setDeleteModalOpen(false)}
                  title={translate("pages.spaces.deleteConfirmation")}
                  size="lg"
                >
                  <div className="flex flex-row justify-between gap-4">
                    <button
                      className="w-full mt-4 ring-1 ring-one-gray-300 border rounded-lg border-one-gray-400 text-one-gray-900 px-4 py-2 font-medium"
                      onClick={() => setDeleteModalOpen(false)}
                    >
                      {translate("buttons.cancel")}
                    </button>
                    <button
                      onClick={() => {
                        // Delete the space
                        handleRemoveRoom()
                      }}
                      className="w-full mt-4 ring-1 border rounded-lg border-one-rose-500 text-one-rose-500 px-4 py-2 font-medium"
                    >
                      {translate("pages.spaces.deleteSpace")}
                    </button>
                  </div>
                </SyModal>
              </div>
            )}
            {activeTab === "links" && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row items-center justify-between w-full">
                  <InputSearch
                    search={search}
                    setSearch={setSearch}
                    placeholder="Enter display name"
                  />
                  <button
                    className="px-4 py-2 rounded-lg bg-one-gray-900 text-white"
                    onClick={() => {
                      setDrawerMode(DRAWER_MODES.ADD_LINKED_DEVICE)
                      showDrawer()
                    }}
                  >
                    <div className="flex flex-row">
                      <img alt="link-icon" src={linkIcon} className="mr-2" />
                      {translate("pages.spaces.linkNewDisplay")}
                    </div>
                  </button>
                </div>
                <div className="flex flex-col w-full mt-4">
                  {isLoading && (
                    <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
                      <div className="flex flex-row">
                        <Skeleton
                          className="mt-2 mr-2"
                          count={1}
                          width={228}
                          height={180}
                        />
                      </div>
                    </SkeletonTheme>
                  )}
                  {!isLoading &&
                  linkedDisplaysCount > 0 &&
                  spaceData.devices !== null ? (
                    <div className="flex gap-4 flex-wrap w-full mb-4">
                      {getFilteredDevices()}
                    </div>
                  ) : (
                    <div className="flex flex-row justify-center w-full">
                      <div className="m-10 flex flex-col text-center">
                        <p className="font-medium">
                          {translate("pages.spaces.noLinksFound")}
                        </p>
                        <p className="text-one-gray-500">
                          {translate("pages.spaces.addNewLinkHint")}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <SyModal
                  open={deleteLinkModalOpen}
                  onClose={() => setDeleteLinkModalOpen(false)}
                  title={translate("pages.spaces.unlinkConfirmation")}
                  size="lg"
                >
                  <div className="flex flex-row justify-between gap-4">
                    <button
                      className="w-full mt-4 ring-1 ring-one-gray-300 border rounded-lg border-one-gray-400 text-one-gray-900 px-4 py-2 font-medium"
                      onClick={() => setDeleteLinkModalOpen(false)}
                    >
                      {translate("buttons.no")}
                    </button>
                    <button
                      onClick={() => {
                        // Unlink display
                        handleRemoveLink(selectedLinkDeviceId)
                      }}
                      className="w-full mt-4 ring-1 border rounded-lg border-one-rose-500 text-one-rose-500 px-4 py-2 font-medium"
                    >
                      {translate("buttons.yes")}
                    </button>
                  </div>
                </SyModal>
              </div>
            )}
          </div>
        )}
      </div>
    </Authenticated>
  )
}
