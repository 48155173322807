import {
  Authenticated,
  usePermissions,
  useQuery,
  useQueryClient,
  useRouterContext,
  useTranslate
} from "@pankod/refine-core"
import { useParams } from "@pankod/refine-react-router-v6"
import { IDevice } from "interfaces"
import { useEffect, useState } from "react"
import { DeleteDevice } from "./delete"
import { TabButton } from "components/globals/TabButton"
import { DeviceSettingsTab } from "./components/DeviceSettingsTab"
import { axiosInstance } from "utilities/dataProvider"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { useLinkedPrinters } from "hooks/useLinkedPrinters"
import { DeviceContainer } from "./components/DeviceContainer"
import { BackButton } from "components/icons"
import { RootState, useAppSelector } from "reduxStore/store"

export const DeviceShow: React.FC = () => {
  const { deviceId } = useParams<"deviceId">()

  const { data: permissionsData } = usePermissions<string>()

  const showLinkedDevicesTab =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Device.All")

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const translate = useTranslate()
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()
  const queryClient = useQueryClient()

  const { data: deviceData, isLoading } = useQuery<IDevice>(
    ["device", deviceId],
    async () => {
      const res = await axiosInstance.get(`devices/${deviceId}`)
      return res.data
    }
  )

  const selectedWorkspaceId = useAppSelector(
    (state: RootState) => state.workspace
  )
  const deviceWorkspaceId = deviceData?.workspace_id

  const { linkedPrintersData } = useLinkedPrinters()
  // Check if the device is linked (either a kiosk linked to a printer or a printer itself)
  const isLinkedToDevice = deviceData
    ? linkedPrintersData.some(
        (printer) =>
          printer.linkedToDeviceId === deviceData.id ||
          printer.device_id === deviceData.id // Check if the current device is a linked printer
      )
    : false

  const [activeTab, setActiveTab] = useState("general")

  useEffect(() => {
    if (
      deviceWorkspaceId &&
      selectedWorkspaceId &&
      deviceWorkspaceId !== +selectedWorkspaceId
    ) {
      void queryClient.invalidateQueries(["devicelist"]).then(() => {
        deviceData?.model.type === "Room Display"
          ? push("../displays")
          : push("../devices")
      })
    }
  }, [deviceWorkspaceId, selectedWorkspaceId, push, queryClient])

  return (
    <Authenticated>
      <div className="container mx-auto">
        {showDeleteModal && (
          <DeleteDevice
            device={deviceData}
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          />
        )}
        <div className="mb-6 flex row h-12 items-center justify-between">
          <button
            onClick={() => {
              deviceData?.model.type === "Room Display"
                ? push("../displays")
                : push("../devices")
            }}
          >
            <BackButton />
          </button>
          <h1 className="text-3xl px-4 font-medium break-all truncate">
            {deviceData?.name}
          </h1>
          <div />
        </div>
        <div className="my-6 border-b border-gray-200">
          <div className="-mb-px flex space-x-8">
            <TabButton
              activeTab={activeTab}
              tabId="general"
              tabTitle={translate("pages.devices.tab.generalSettings")}
              setActiveTab={setActiveTab}
            />
            {deviceData?.model.type !== "Printer" && (
              <TabButton
                activeTab={activeTab}
                tabId="settings"
                tabTitle={translate("pages.devices.tab.customization")}
                setActiveTab={setActiveTab}
              />
            )}
            {showLinkedDevicesTab &&
              deviceData?.model.type !== "Room Display" && (
                <TabButton
                  activeTab={activeTab}
                  tabId="links"
                  tabTitle={translate("pages.devices.tab.linkedDevices")}
                  setActiveTab={setActiveTab}
                />
              )}
          </div>
        </div>
        {isLoading && (
          <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
            <div className="flex flex-row">
              <Skeleton
                className="mt-2 mr-2"
                count={1}
                width={260}
                height={400}
              />
              <Skeleton className="mt-2" count={1} width={750} height={400} />
            </div>
          </SkeletonTheme>
        )}
        {deviceData && (
          <div className="flex row gap-2">
            <div className="w-80 flex-col">
              <DeviceContainer
                id={deviceData?.id}
                device={deviceData}
                isDeviceShow={true}
                isLinkedToDevice={isLinkedToDevice}
              />
            </div>
            <div
              className={`w-full px-4 relative flex flex-col justify-between`}
            >
              <DeviceSettingsTab
                device={deviceData}
                activeTab={activeTab}
                deviceId={deviceData?.id}
              />
            </div>
          </div>
        )}
      </div>
    </Authenticated>
  )
}
