import { CheckIcon } from "@heroicons/react/20/solid"
import { useCustom, useQueryClient } from "@pankod/refine-core"
import { IWorkspace } from "interfaces"
import { useEffect } from "react"
import { Tooltip } from "react-tooltip"
import { setSelectedWorkspace } from "reduxStore/reducers/workspaceReducer"
import { useAppDispatch, useAppSelector } from "reduxStore/store"
import { LOG, Logger } from "utilities/logger"
import { QUERY_KEYS } from "utilities/types"

type WorkspacePickerProps = {
  showDropdown: boolean
  setDropdown: (arg0: boolean) => void
}

export const WorkspacePicker = (props: WorkspacePickerProps) => {
  const { data: workspaceData, isLoading } = useCustom<[IWorkspace]>({
    url: "workspaces/",
    method: "get"
  })

  const dispatch = useAppDispatch()
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  useEffect(() => {
    if (!isLoading && !selectedWorkspaceId) {
      const { id } = workspaceData?.data[0] || {}
      dispatch(setSelectedWorkspace(id))
    }
  }, [isLoading, selectedWorkspaceId])

  const currentWorkspace = workspaceData?.data?.at(
    workspaceData?.data.findIndex(
      (obj) => obj.id === parseInt(selectedWorkspaceId || "")
    )
  )

  const currentWorkspaceName = currentWorkspace?.name
  const currentOrganizationName = currentWorkspace?.organization?.name
  const workspaceCount = workspaceData?.data?.length ?? 0

  let css =
    "px-2 space-x-2 flex items-center w-full h-16 rounded-lg text-left text-sm bg-white cursor-default"
  if (workspaceCount > 1) {
    css += " cursor-pointer shadow hover:bg-one-gray-50"
  }

  return (
    <div className="relative z-auto">
      <div
        role="button"
        tabIndex={0}
        aria-expanded={props.showDropdown}
        aria-controls="workspace-dropdown"
        className={css}
        onClick={() => {
          props.setDropdown(!props.showDropdown) // Toggle using the current boolean value
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault()
            props.setDropdown(!props.showDropdown) // Toggle using the current boolean value
          }
        }}
      >
        <div className="flex flex-shrink-0 items-center justify-center rounded-full font-medium uppercase bg-one-rose-200 w-8 h-8">
          {currentOrganizationName?.charAt(0)}
        </div>

        <div className="flex-grow overflow-hidden inline">
          <div className="truncate">{currentWorkspaceName}</div>
          <div className="truncate text-one-gray-400">
            {currentOrganizationName}
          </div>
        </div>

        {workspaceCount > 1 && (
          <div className="flex-shrink-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M16 28L9 21 10.41 19.59 16 25.17 21.59 19.59 23 21 16 28zM16 4L23 11 21.59 12.41 16 6.83 10.41 12.41 9 11 16 4z"></path>
            </svg>
          </div>
        )}
      </div>

      {workspaceCount > 1 && props.showDropdown && (
        <WorkspaceDropdown
          workspaces={workspaceData?.data}
          selectedWorkspaceId={selectedWorkspaceId}
          setDropdown={props.setDropdown}
        />
      )}

      <Tooltip id="workspace-picker-tooltip" style={{ zIndex: 99 }} />
    </div>
  )
}

const WorkspaceDropdown = ({
  workspaces,
  selectedWorkspaceId,
  setDropdown
}: {
  workspaces: [IWorkspace] | undefined
  selectedWorkspaceId: string | null
  setDropdown: (arg0: boolean) => void
}) => {
  const dispatch = useAppDispatch()
  const workspaceId = selectedWorkspaceId && parseInt(selectedWorkspaceId)
  const queryClient = useQueryClient()

  return (
    <div
      style={{ animation: "scaleIn 0.125s" }}
      className="mt-1 p-1 absolute origin-top-left shadow rounded-lg bg-white w-72 z-10"
    >
      {workspaces?.map(({ name, id }, index) => (
        <button
          className="p-1 space-x-2 flex items-center w-full rounded-md text-left text-one-gray-600 hover:bg-one-gray-50"
          data-tooltip-id="workspace-picker-tooltip"
          data-tooltip-content={name}
          data-tooltip-place="right"
          key={id}
          onClick={() => {
            void Logger().log(LOG.PRESS_WORKSPACE_ITEM, `pressed ${id}`)
            queryClient.removeQueries({
              queryKey: [QUERY_KEYS.WORKSPACE_SETTING]
            })
            dispatch(setSelectedWorkspace(id))
            setDropdown(false)
          }}
        >
          <div className="flex flex-shrink-0 items-center justify-center rounded-full font-medium uppercase bg-blue-200 w-8 h-8">
            {name.charAt(0)}
          </div>

          <div
            className={`flex-grow truncate ${
              workspaceId === id ? "font-medium text-black" : ""
            }`}
          >
            {name}
          </div>

          {workspaceId === id && (
            <div className="flex flex-shrink-0 justify-center items-center">
              <CheckIcon className="text-emerald-500 h-4 w-4" />
            </div>
          )}
        </button>
      ))}
    </div>
  )
}
