import { UseModalFormReturnType } from "@pankod/refine-react-hook-form"
import { useOne, useTranslate, useUpdate } from "@pankod/refine-core"
import { useState, useEffect, useRef, ChangeEvent } from "react"
import { Modal } from "../../components/Modal"
import {
  IContactDetail,
  IHostUpdate,
  IMandatoryFields,
  WorkspaceSetting
} from "interfaces"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import ErrorText from "components/formMessage"
import { SELECTED_WORKSPACE } from "utilities/constants"
import validators from "utilities/formValidator"
import { axiosInstance, axiosNoUrl } from "utilities/dataProvider"
import { isEmptyString } from "utilities/string"
import { useQueryClient } from "@tanstack/react-query"
import { Button, ButtonStyle } from "components/globals/Button"
import { Input } from "components/globals/Input"
import { contactDetailType } from "modules/ContactDetails/contactDetailType"
import { shouldSendNotification } from "modules/ContactDetails/contactDetailPrimaryNotification"
import { ContactDetailWorkspaceNotificationEnablement } from "modules/ContactDetails/ContactDetailWorkspaceNotificationEnablement"
import { Toggler } from "components/globals/Toggler"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentSms, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { UserIcon } from "@heroicons/react/24/outline"

export const EditHost: React.FC<UseModalFormReturnType> = ({
  refineCore: { queryResult, formLoading },
  modal: { visible, close }
}) => {
  const translate = useTranslate()
  const { mutate } = useUpdate<IHostUpdate>()
  const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE) || ""
  const { data: sendEmailWorkspaceSettingData } = useOne<WorkspaceSetting>({
    resource: `workspaces/${selectedWorkspaceId}/settings/sendemail`,
    id: ""
  })
  const { data: sendSmsWorkspaceSettingData } = useOne<WorkspaceSetting>({
    resource: `workspaces/${selectedWorkspaceId}/settings/sendsms`,
    id: ""
  })

  // TODO: Set these values somewhere more global
  const mandatoryFields: IMandatoryFields = {
    firstName: true,
    lastName: true,
    phone: false,
    email: true
  }
  const [errors, setErrors] = useState<string[]>([])

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [picture, setPicture] = useState("")
  const [sendEmail, setSendEmail] = useState(false)
  const [sendSms, setSendSms] = useState(false)
  const [preview, setPreview] = useState("")
  const [bucketImage, setBucketImage] = useState("")
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  )
  const inputFile = useRef<HTMLInputElement | null>(null)
  const queryClient = useQueryClient()
  const openFileBrowser = () => {
    // `current` points to the mounted file input element
    inputFile.current?.click()
  }

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const target = event.target
    const file = target.files?.[0]

    setSelectedImage(file)
  }

  useEffect(() => {
    if (!formLoading) {
      setFirstName((queryResult?.data?.data.person.first_name as string) ?? "")
      setLastName((queryResult?.data?.data.person.last_name as string) ?? "")
      setEmail((queryResult?.data?.data.person.email as string) ?? "")
      setPhone((queryResult?.data?.data.person.phone as string) ?? "")
      setPicture((queryResult?.data?.data.picture as string) ?? "")
      setErrors([])
      setBucketImage("")
      setPreview("")

      const contactDetails = queryResult?.data?.data
        ?.contact_details as Array<IContactDetail>
      if (contactDetails?.length > 0) {
        setSendSms(
          shouldSendNotification({
            contactDetails,
            contactDetailType: contactDetailType.phone
          }) ?? false
        )
        setSendEmail(
          shouldSendNotification({
            contactDetails,
            contactDetailType: contactDetailType.email
          }) ?? false
        )
      }
    }
  }, [formLoading])

  const cancelPreview = () => {
    setPreview("")
  }

  const deletePhoto = () => {
    setPicture("")
    setBucketImage("")
    setPreview("")
    setSelectedImage(undefined)
  }

  useEffect(() => {
    const uploadPhoto = async () => {
      let publicUrl = ""

      const getSignedUrl = async () => {
        if (selectedImage !== undefined) {
          try {
            const organizationId =
              queryResult?.data?.data.person.organization_id
            const res = await axiosInstance.post(
              `/organizations/${organizationId}/hosts/signed-url`,
              {
                blob: selectedImage?.name
              }
            )
            const signedUrl: string = res.data.signed_url
            publicUrl = res.data.public_url

            void axiosNoUrl.put(signedUrl, selectedImage, {
              headers: {
                "Content-type": selectedImage.type
              }
            })
          } catch (error) {
            customToast.error(translate("notifications.fileUploadError"))
            void Logger().error(LOG.SIGNED_URL, `${error}`)
          }
        }
      }
      await getSignedUrl()

      setBucketImage(publicUrl)
    }

    if (!selectedImage) {
      setPreview("")
    } else if (selectedImage !== undefined) {
      const objectUrl = URL.createObjectURL(selectedImage)
      setPreview(objectUrl)
      void uploadPhoto()

      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [selectedImage])

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    void Logger().log(LOG.EDIT_HOST)

    const pic = !isEmptyString(bucketImage)
      ? bucketImage
      : e.target["picture"].value

    if (validate()) {
      const hostId = queryResult?.data?.data.id
      mutate(
        {
          resource: `hosts`,
          id: hostId as number,
          values: {
            first_name: e.target["firstName"].value,
            last_name: e.target["lastName"].value,
            email: [
              {
                primary: true,
                notifications: sendEmail,
                value: e.target["email"].value
              }
            ],
            phone: [
              {
                primary: true,
                notifications: sendSms,
                value: e.target["phone"].value
              }
            ],
            picture: pic
          }
        },
        {
          onError: (error, variables, context) => {
            customToast.error(
              translate("notifications.editError", {
                resource: translate("resources.host")
              })
            )
            void Logger().error(LOG.EDIT_HOST, `${error.stack}`)
          },
          onSuccess: (data, variables, context) => {
            customToast.success(
              translate("notifications.editSuccess", {
                resource: translate("resources.host")
              })
            )
            close()
          },
          onSettled(data, error, variables, context) {
            setTimeout(() => void queryClient.invalidateQueries(), 100)
          }
        }
      )
    }
  }

  /**
   * Validate fields
   * @returns True if no errors
   */
  function validate(): boolean {
    const validationErrors = validators.handleValidation(
      firstName,
      lastName,
      phone,
      email,
      mandatoryFields
    )
    setErrors(validationErrors)
    return validationErrors["firstName"] ||
      validationErrors["lastName"] ||
      validationErrors["email"] ||
      validationErrors["phone"]
      ? false
      : true
  }

  const handleInputChange = (e) => {
    const { id, value } = e.target
    if (id === "firstName") {
      setFirstName(value as string)
    }
    if (id === "lastName") {
      setLastName(value as string)
    }
    if (id === "email") {
      setEmail(value as string)
    }
    if (id === "phone") {
      setPhone(value as string)
    }
    if (id === "picture") {
      setPicture(value as string)
    }
  }

  return (
    <Modal
      title={translate("pages.hosts.editHost")}
      isOpen={visible}
      onClose={close}
    >
      <form className="form" onSubmit={onSubmit}>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={(e) => onChangeFile(e)}
        />
        <div className="flex flex-col gap-2 px-6">
          <div className="flex flex-col gap-4 pb-6">
            <div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-1 items-center justify-center">
                  {preview || picture ? (
                    <img
                      alt="hostpic"
                      width="96"
                      height="96"
                      className="rounded-full object-cover aspect-square"
                      src={preview ? preview : picture}
                    ></img>
                  ) : (
                    <div>
                      <UserIcon className="h-14 w-14 text-systam-blue" />
                    </div>
                  )}
                </div>
                <div className="flex flex-1 items-center justify-start">
                  <div className="flex-col">
                    <div>
                      <Button
                        type="button"
                        onClick={openFileBrowser}
                        style={ButtonStyle.BluePrimary}
                        name={
                          !preview && !picture
                            ? translate("form.addPhoto")
                            : translate("form.changePhoto")
                        }
                      />
                    </div>
                    {picture && !preview && (
                      <div>
                        <Button
                          type="button"
                          onClick={deletePhoto}
                          style={ButtonStyle.TransparentNoBorder}
                          name={translate("form.deletePhoto")}
                        />
                      </div>
                    )}
                    {preview && (
                      <>
                        <div>
                          <Button
                            type="button"
                            style={ButtonStyle.TransparentBorder}
                            onClick={cancelPreview}
                            name={translate("buttons.cancel")}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-1">
            <Input
              label={translate("form.firstName")}
              placeholder={translate("form.firstName")}
              id="firstName"
              type="text"
              value={firstName}
              required={mandatoryFields.firstName}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="flex flex-1">
            <Input
              label={translate("form.lastName")}
              placeholder={translate("form.lastName")}
              id="lastName"
              type="text"
              value={lastName}
              required={mandatoryFields.lastName}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between gap-4 -mt-1">
          <div className="flex flex-1">
            {errors["firstName"] && ErrorText(errors["firstName"])}
          </div>
          <div className="flex flex-1">
            {errors["lastName"] && ErrorText(errors["lastName"])}
          </div>
        </div>
        <div>
          <Input
            label={translate("form.email")}
            placeholder={translate("form.email")}
            id="email"
            type="text"
            value={email}
            required={mandatoryFields.email}
            onChange={(e) => handleInputChange(e)}
          />
          <div className="pt-1">
            {errors["email"] && ErrorText(errors["email"])}
          </div>
        </div>
        <div>
          <Input
            label={translate("form.phone")}
            placeholder={translate("form.phone")}
            id="phone"
            type="text"
            value={phone}
            required={mandatoryFields.phone}
            onChange={(e) => handleInputChange(e)}
          />
          <div className="pt-1">
            {errors["phone"] && ErrorText(errors["phone"])}
          </div>
        </div>
        <div>
          <input
            type="text"
            value={picture}
            onChange={(e) => handleInputChange(e)}
            id="picture"
            placeholder={translate("form.pictureUrl")}
            style={{ display: "none" }}
          />
        </div>
        <div className="flex justify-between mt-4">
          <div className="flex py-4 px-4 border border-gray-400 rounded-lg w-full">
            <Toggler
              className="flex w-full"
              icon={
                <FontAwesomeIcon
                  icon={faCommentSms}
                  size="lg"
                  className="text-systam-blue"
                />
              }
              header={translate("pages.hosts.enableSmsNotification")}
              description={translate(
                "pages.hosts.enableSmsNotificationDescription"
              )}
              checked={sendSms}
              onChange={() => setSendSms(!sendSms)}
            />
          </div>
        </div>
        {sendSmsWorkspaceSettingData?.data.value === "false" && (
          <ContactDetailWorkspaceNotificationEnablement
            workspaceNotificationDisabled={true}
          />
        )}
        <div className="flex justify-between mt-4">
          <div className="flex py-4 px-4 border border-gray-400 rounded-lg w-full">
            <Toggler
              icon={
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="lg"
                  className="text-systam-blue"
                />
              }
              header={translate("pages.hosts.enableEmailNotification")}
              description={translate(
                "pages.hosts.enableEmailNotificationDescription"
              )}
              checked={sendEmail}
              onChange={() => setSendEmail(!sendEmail)}
            />
          </div>
        </div>
        {sendEmailWorkspaceSettingData?.data.value === "false" && (
          <ContactDetailWorkspaceNotificationEnablement
            workspaceNotificationDisabled={true}
          />
        )}
        <div className="py-6 flex justify-around">
          <Button
            style={ButtonStyle.TransparentBorder}
            onClick={() => {
              void Logger().log(LOG.PRESS_CANCEL_EDIT_HOST_MODAL)
              close()
            }}
            name={translate("buttons.cancel")}
          />
          <Button
            type="submit"
            style={ButtonStyle.BluePrimary}
            name={
              formLoading ? translate("loading") : translate("buttons.save")
            }
          />
        </div>
      </form>
    </Modal>
  )
}
