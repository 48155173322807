import {
  IResourceComponentsProps,
  useTranslate,
  useResource,
  useCan
} from "@pankod/refine-core"
import { ISettingBlock, ISettingEntity, ISettingWrap } from "interfaces"
import React, { useEffect, useState } from "react"
import { useAppSelector } from "reduxStore/store"
import { axiosInstance } from "utilities/dataProvider"
import { SettingsContainer } from "./SettingContainer"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { QUERY_KEYS } from "utilities/types"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { LOG, Logger } from "utilities/logger"
import posthog from "posthog-js"

export const SettingsList: React.FC<IResourceComponentsProps> = () => {
  const resource = useResource()
  const translate = useTranslate()

  const [settingHeaders, setSettingHeaders] = useState<ISettingWrap[]>()

  const [currentSetting, setCurrentSetting] = useState<ISettingWrap>()

  const [resourceName, setResourceName] = useState<string>("")

  const selectedWorkspaceId = useAppSelector((state) => state.workspace)
  const queryClient = useQueryClient()

  const { data: canAccessVisitModule } = useCan({
    resource: "visits",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["visit"]
        }
      }
    }
  })

  const { data: canAccessRoomsModule } = useCan({
    resource: "rooms",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["rooms"]
        }
      }
    }
  })

  // Checks if setting is locked behind a feature flag or a module
  function getSettingHeaderPermission(settingsHeader: ISettingWrap): boolean {
    if (
      (settingsHeader.featureFlag === undefined ||
        posthog.isFeatureEnabled(settingsHeader.featureFlag)) &&
      (settingsHeader.module === undefined ||
        (settingsHeader.module === "visit" && canAccessVisitModule?.can) ||
        (settingsHeader.module === "rooms" && canAccessRoomsModule?.can))
    ) {
      return true
    } else return false
  }

  // Find first setting that does not have category
  function findFirstSetting(settings: ISettingWrap[]) {
    if (settings) {
      settings.some((settingHeader, i) => {
        if (
          settingHeader.category === undefined &&
          settingHeader.header !== undefined &&
          settingHeader.settingBlock.length > 0
        ) {
          setCurrentSetting(settings[i])
          return settingHeader
        }
        return false
      })
    }
  }

  useEffect(() => {
    // Always invalidate queries when component mounts
    queryClient
      .invalidateQueries([QUERY_KEYS.WORKSPACE_SETTING])
      .then(() => {
        // Successfully invalidated the cache, forcing refetch
      })
      .catch((error) => {
        void Logger().error(LOG.WORKSPACE_SETTINGS, `${error}`)
      })
  }, [queryClient])

  useEffect(() => {
    setResourceName(resource?.resource.name)
    if (!settingHeaders) {
      let allSettings: ISettingWrap[] = []
      for (const setting of resource?.resource?.options
        ?.settingWrap as ISettingWrap[][]) {
        allSettings = [...allSettings, ...setting]
      }

      setSettingHeaders(allSettings)
    }

    // Resource has changed, reset the current setting
    if (settingHeaders && resourceName !== resource?.resource.name) {
      let settingWrap: ISettingWrap[] = []

      for (const setting of resource?.resource?.options
        ?.settingWrap as ISettingWrap[][]) {
        settingWrap = [...settingWrap, ...setting]
      }

      // Check if setting headers are different
      if (
        settingHeaders.length !== settingWrap.length ||
        settingHeaders.some(
          (header, i) => header?.header !== settingWrap[i]?.header
        )
      ) {
        const settings: ISettingWrap[] = [
          ...settingWrap.map((settingsHeader): ISettingWrap => {
            if (getSettingHeaderPermission(settingsHeader)) {
              return {
                category: settingsHeader.category,
                header: settingsHeader.header,
                settingBlock: settingsHeader.settingBlock,
                featureFlag: settingsHeader.featureFlag
              }
            } else {
              return {
                settingBlock: []
              }
            }
          })
        ]

        findFirstSetting(settings)
        setSettingHeaders(settings)
      }
    }
  }, [resource, selectedWorkspaceId, resourceName])

  useEffect(() => {
    if (currentSetting === undefined && settingHeaders) {
      findFirstSetting(settingHeaders)
    }
  }, [settingHeaders])

  const { data, isLoading, isFetching } = useQuery(
    [QUERY_KEYS.WORKSPACE_SETTING],
    async () => {
      const res = await axiosInstance.get(
        `/workspaces/${selectedWorkspaceId}/settings`
      )
      return res.data
    },
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (e) => {
        void Logger().error(LOG.WORKSPACE_SETTINGS, `${e}`)
      }
    }
  )

  useEffect(() => {
    if (isLoading) return

    const backendSettings: ISettingEntity[] = data?.settings || []

    const isDifferent = (
      settingEntity: ISettingEntity,
      backendSettingEntity: ISettingEntity
    ): boolean =>
      backendSettingEntity.setting_metadata.key ===
      settingEntity.setting_metadata.key

    /**
     * This function loops through ISettingWrap Array and finds all ISettingEntity objects,
     * then compares these objects to the settings array coming from the backend.
     *
     * If the local settingEntity with a same meta_data.key matches with a setting coming from backend,
     * then change the value to match the backend setting
     *
     */
    if (settingHeaders && settingHeaders?.length > 0) {
      const modifiedSettings: ISettingWrap[] = [
        ...settingHeaders
          .map((settingsHeader): ISettingWrap => {
            if (
              getSettingHeaderPermission(settingsHeader) &&
              settingsHeader.settingBlock
            ) {
              return {
                category: settingsHeader.category,
                header: settingsHeader.header,
                settingBlock: [
                  ...settingsHeader.settingBlock.map(
                    (settingBlock: ISettingBlock) => {
                      return {
                        subHeader: settingBlock.subHeader,
                        cardDescription: settingBlock.cardDescription,
                        settings: [
                          ...settingBlock.settings.map(
                            (settingEntity): ISettingEntity[] => {
                              return [
                                ...settingEntity.map(
                                  (settingFromRow: ISettingEntity) => {
                                    for (const backendSetting of backendSettings) {
                                      if (
                                        isDifferent(
                                          settingFromRow,
                                          backendSetting
                                        )
                                      ) {
                                        return {
                                          ...settingFromRow,
                                          value: backendSetting.value
                                        }
                                      }
                                    }
                                    /**
                                     * if corresbonding backendSettings is not found, then just set the value to "false",
                                     * TODO: this will need more logic because if the setting type is text area, then the default value should be set to empty string
                                     */
                                    return {
                                      ...settingFromRow,
                                      value: "false"
                                    }
                                  }
                                )
                              ]
                            }
                          )
                        ]
                      }
                    }
                  )
                ]
              }
            } else {
              return {
                settingBlock: []
              }
            }
          })
          .filter(
            // Filter out empty items
            (settingHeader) =>
              settingHeader.settingBlock.length > 0 || settingHeader.category
          )
      ]

      setSettingHeaders(modifiedSettings)

      if (currentSetting !== undefined && modifiedSettings) {
        setCurrentSetting(
          modifiedSettings.find(
            (settingHeader) => settingHeader.header === currentSetting.header
          )
        )
      } else {
        // set current setting tab to the first
        setCurrentSetting(modifiedSettings[0])
      }
    }
  }, [data, isLoading, selectedWorkspaceId])

  return (
    <>
      <h1 className="flex justify-between items-center font-kanit font-medium text-3xl h-20">
        {translate("pages.settings.settings")}
      </h1>

      <div className="flex">
        <div className="pt-6">
          {settingHeaders?.map((settingsObj, index) => {
            if (settingsObj.category === "true") {
              return (
                <p
                  key={`cat_${settingsObj.header}`}
                  className={[
                    "ml-4 p-1 mb-3 text-one-gray-950 font-semibold",
                    index > 0 && "mt-8"
                  ].join(" ")}
                >
                  {settingsObj.header && translate(settingsObj.header)}
                </p>
              )
            } else if (settingsObj?.settingBlock?.length === 0) {
              return null
            } else {
              return (
                <button
                  key={`tab_${settingsObj.header}`}
                  onClick={() => {
                    setCurrentSetting(settingsObj)
                    // remove on flight queries
                    queryClient
                      .invalidateQueries([QUERY_KEYS.WORKSPACE_SETTING])
                      .then(() => {
                        // Successfully invalidated the cache
                        void Logger().log(
                          LOG.WORKSPACE_SETTINGS,
                          "Successfully invalidated the cache addNewLinkHint cleared"
                        )
                      })
                      .catch((error) => {
                        void Logger().error(LOG.WORKSPACE_SETTINGS, `${error}`)
                      })
                  }}
                  className={[
                    "flex w-full cursor-pointer hover:bg-gray-100 ml-4 p-1 mb-3 rounded-md font-medium ",
                    currentSetting?.header === settingsObj.header
                      ? "text-systam-blue"
                      : "text-one-gray-500"
                  ].join(" ")}
                >
                  {settingsObj.header && translate(settingsObj.header)}
                </button>
              )
            }
          })}
        </div>
        {isLoading ||
          (isFetching && (
            <div className="flex flex-1 pl-10 pr-6 pt-3">
              <SkeletonTheme
                baseColor="#f4f4f4"
                highlightColor="#fcfcfc"
                borderRadius={16}
              >
                <Skeleton
                  height={600}
                  containerClassName="flex-1"
                  enableAnimation
                />
              </SkeletonTheme>
            </div>
          ))}
        {!isFetching && currentSetting && (
          <SettingsContainer
            header={translate(currentSetting?.header as string)}
            settingBlock={currentSetting.settingBlock}
          />
        )}
      </div>
    </>
  )
}
