import {
  Authenticated,
  useQuery,
  useQueryClient,
  useRouterContext,
  useTranslate
} from "@pankod/refine-core"
import { useParams } from "@pankod/refine-react-router-v6"
import { IDevice } from "interfaces"
import { useEffect, useState } from "react"
import { DeleteDevice } from "./delete"
import { TabButton } from "components/globals/TabButton"
import { ROLE_KEY } from "utilities/constants"
import { axiosInstance } from "utilities/dataProvider"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { BackButton } from "components/icons"
import { DeviceContainer } from "pages/devices/components/DeviceContainer"

export const DisplayShow: React.FC = () => {
  const { deviceId } = useParams<"deviceId">()

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const userRole = localStorage.getItem(ROLE_KEY)
  const isSuperAdmin = userRole?.includes(
    process.env.REACT_APP_USER_ROLE as string
  )
    ? true
    : false

  const translate = useTranslate()
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()
  const queryClient = useQueryClient()

  useEffect(() => {
    void queryClient.resetQueries({
      queryKey: [`device`]
    })
  }, [])

  const { data: deviceData, isLoading } = useQuery<IDevice>(
    ["device"],
    async () => {
      const res = await axiosInstance.get(`devices/${deviceId}`)
      return res.data
    }
  )

  const [activeTab, setActiveTab] = useState("general")

  return (
    <Authenticated>
      <div className="container mx-auto">
        {showDeleteModal && (
          <DeleteDevice
            device={deviceData}
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          />
        )}
        <div className="mb-6 flex row h-12 items-center justify-between">
          <button
            onClick={() => {
              push("../devices")
            }}
          >
            <BackButton />
          </button>
          <h1 className="text-3xl font-medium">{deviceData?.name}</h1>
          <div></div>
        </div>
        <div className="my-6 border-b border-gray-200">
          <div className="-mb-px flex space-x-8">
            <TabButton
              activeTab={activeTab}
              tabId="general"
              tabTitle={translate("pages.devices.tab.generalSettings")}
              setActiveTab={setActiveTab}
            />
            {deviceData?.model.type !== "Printer" && (
              <TabButton
                activeTab={activeTab}
                tabId="settings"
                tabTitle={translate("pages.devices.tab.customization")}
                setActiveTab={setActiveTab}
              />
            )}
            {isSuperAdmin && (
              <TabButton
                activeTab={activeTab}
                tabId="links"
                tabTitle={translate("pages.devices.tab.linkedDevices")}
                setActiveTab={setActiveTab}
              />
            )}
          </div>
        </div>
        {isLoading && (
          <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
            <div className="flex flex-row">
              <Skeleton
                className="mt-2 mr-2"
                count={1}
                width={260}
                height={400}
              />
              <Skeleton className="mt-2" count={1} width={750} height={400} />
            </div>
          </SkeletonTheme>
        )}
        {deviceData && (
          <div className="flex row gap-2">
            <div className="w-80 flex-col">
              <DeviceContainer
                id={deviceData?.id}
                device={deviceData}
                isDeviceShow={true}
              />
              <div>
                {isSuperAdmin && (
                  <button
                    className="flex flex-grow w-full items-center justify-center rounded-md border border-transparent bg-rose-50 px-4 py-2 my-2 font-medium text-rose-600 shadow-sm hover:bg-rose-100 focus:outline-none focus:ring-2 focus:ring-systam-600 focus:ring-offset-2"
                    onClick={() => {
                      setShowDeleteModal(true)
                    }}
                  >
                    {translate("buttons.delete")}
                  </button>
                )}
              </div>
            </div>
            <div
              className={`w-full px-4 py-4 relative flex flex-col justify-between border-2 rounded-md bg-white shadow-sm border-gray-100 `}
            ></div>
          </div>
        )}
      </div>
    </Authenticated>
  )
}
