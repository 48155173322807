import { ITreeMenu, useMenu, useRouterContext } from "@pankod/refine-core"
import { LOG, Logger } from "utilities/logger"

export const NavItem = ({ route, icon, label }: ITreeMenu) => {
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()
  const { selectedKey } = useMenu()
  const isSelected = route === selectedKey

  return (
    <button
      onClick={() => {
        void Logger().log(LOG.CHANGE_PAGE, route)
        push(route)
      }}
      className={`px-2 flex items-center rounded-lg h-12 w-full space-x-2 ${
        isSelected
          ? "bg-white hover:bg-one-gray-50 shadow-sm"
          : "hover:bg-one-gray-200/25"
      }`}
    >
      <div
        className={`flex justify-center items-center w-6 h-6 ${
          isSelected ? "text-one-rose-500" : "text-one-gray-500"
        }`}
      >
        {icon}
      </div>
      <div className={`${isSelected ? "text-one-rose-500" : ""}`}>{label}</div>
    </button>
  )
}
