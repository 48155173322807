import React, { useState } from "react"
import {
  useMenu,
  useRouterContext,
  ITreeMenu,
  usePermissions,
  useCan
} from "@pankod/refine-core"
import { Home } from "icons/icons"
import {
  faCertificate,
  faPeopleArrows,
  faPersonShelter
} from "@fortawesome/free-solid-svg-icons"
import { Logo } from "../Logo"
import { usePostHog } from "posthog-js/react"
import { NavItem } from "./NavItem"
import { AccordionNavItem } from "./AccordionNavItem"
import { getPermittedMenuItems } from "utilities/permissionSelector"
import { WorkspacePicker } from "./WorkspacePicker"
import { Accordion } from "./Accordion"

export const Sider: React.FC = () => {
  const { menuItems } = useMenu()
  const [showDropdown, setShowDropdown] = useState(false)
  const { data: permissionsData } = usePermissions<string>()

  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  const posthog = usePostHog()

  const { data: canAccessVisitModule, isLoading } = useCan({
    resource: "visits",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["visit"]
        }
      }
    }
  })

  const { data: canAccessRoomsModule } = useCan({
    resource: "rooms",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["rooms"]
        }
      }
    }
  })

  const newMenuItems = [
    {
      name: "/",
      route: "/",
      icon: <Home />,
      children: [],
      label: "Home"
    },
    ...getPermittedMenuItems(permissionsData, menuItems)
  ]

  // Filter and sort menu items
  function getNavItems(menuItems: ITreeMenu[]): JSX.Element {
    let home: JSX.Element | null = null
    const visitItems: JSX.Element[] = []
    const roomsItems: JSX.Element[] = []

    const items = menuItems
      .map((item) => {
        const { key, name, children, ...otherProps } = item

        if (name === "/") {
          // Home menu item
          home = (
            <NavItem key={key} {...otherProps} name={name}>
              {children}
            </NavItem>
          )
          return null
        } else if (
          name === "visits" ||
          name === "devices/" ||
          name === "hosts/" ||
          name === "visitors/"
        ) {
          // Visit related items
          visitItems.push(
            <AccordionNavItem key={key} name={name} {...otherProps}>
              {children}
            </AccordionNavItem>
          )
          return null
        } else if (
          name === "displays/" ||
          name === "calendar-integrations/" ||
          name === "spaces/"
        ) {
          if (name === "spaces/" && !posthog.isFeatureEnabled("show-spaces")) {
            return null
          }
          // Room related items
          roomsItems.push(
            <AccordionNavItem key={key} name={name} {...otherProps}>
              {children}
            </AccordionNavItem>
          ) // Pass key directly
          return null
        } else {
          // Other menu items
          return (
            <NavItem key={key} {...otherProps} name={name}>
              {children}
            </NavItem>
          )
        }
      })
      .filter((item) => item !== null)

    return (
      <div className="mt-8 space-y-2">
        {home && <div className="h-12">{home}</div>}
        <Accordion
          title="Visits"
          icon={faPeopleArrows}
          locked={!(visitItems.length > 0 && canAccessVisitModule?.can)}
          loading={isLoading}
        >
          <>{visitItems}</>
        </Accordion>
        <Accordion title="Inductions" icon={faCertificate} locked={true} />
        <Accordion
          title="Rooms"
          icon={faPersonShelter}
          locked={!(roomsItems.length > 0 && canAccessRoomsModule?.can)}
          loading={isLoading}
        >
          <>{roomsItems}</>
        </Accordion>
        {items.length > 0 && items}
      </div>
    )
  }

  return (
    <div className="px-4 w-72">
      <div className="flex flex-col justify-between">
        <div className="flex flex-col">
          <div className="flex items-start justify-between">
            <div className="flex justify-center items-center h-20">
              <button
                className=""
                onClick={() => {
                  push("/")
                }}
              >
                <Logo className="h-6" />
              </button>
            </div>
          </div>
          <WorkspacePicker
            showDropdown={showDropdown}
            setDropdown={setShowDropdown}
          />
          {getNavItems(newMenuItems)}
        </div>
      </div>
    </div>
  )
}
