import { useQuery, useTranslate } from "@pankod/refine-core"
import { Button, ButtonStyle } from "components/globals/Button"
import { ILinkedDevice } from "interfaces"
import { useState } from "react"
import { axiosInstance } from "utilities/dataProvider"
import { AddDeviceLink } from "./AddDeviceLinkModal"
import { RemoveLinkModal } from "./RemoveLinkModal"
import { DeviceImage } from "./DeviceImage"

export const LinkedDevicesTab = ({ deviceId }: { deviceId: number }) => {
  const translate = useTranslate()

  const { data: linkedDevicesData } = useQuery<[ILinkedDevice]>(
    ["deviceLinks"],
    async () => {
      const res = await axiosInstance.get(`devices/${deviceId}/links`)
      return res.data
    }
  )

  const [showAddDeviceLinkModal, setShowAddDeviceLinkModal] =
    useState<boolean>(false)
  const [showRemoveDeviceLinkModal, setShowRemoveDeviceLinkModal] =
    useState<boolean>(false)

  const [deviceLinkId, setDeviceLinkId] = useState<number>()

  const getConnectionDisplayName = (connectionName) => {
    switch (connectionName) {
      case "usb":
        return "USB"
      case "wifi":
        return "Wifi"
      case "ethernet":
        return "Ethernet"
      default:
        return ""
    }
  }

  return (
    <div>
      {showAddDeviceLinkModal && (
        <AddDeviceLink
          deviceId={deviceId}
          open={showAddDeviceLinkModal}
          onClose={() => setShowAddDeviceLinkModal(false)}
        />
      )}
      {showRemoveDeviceLinkModal && (
        <RemoveLinkModal
          deviceLinkId={deviceLinkId}
          open={showRemoveDeviceLinkModal}
          onClose={() => setShowRemoveDeviceLinkModal(false)}
        />
      )}
      <div className="items-start ">
        <h2 className="text-lg font-medium leading-6 text-gray-900">
          {translate("pages.devices.tab.linkedDevices")}
        </h2>
        <p className="mt-1 text-gray-500">
          {translate("pages.devices.tab.linkedDevicesDescription")}
        </p>
        <div className="divide-y-2 divide-slate-300/25 xl:w-2/3"></div>
        <Button
          name={translate("pages.devices.addNewDeviceLink")}
          onClick={() => setShowAddDeviceLinkModal(true)}
        />
        <div>
          {linkedDevicesData && linkedDevicesData.length < 1 && (
            <div className="pt-2">
              <div>
                <p>{translate("pages.devices.noLinkedDevices")}</p>
                <p>{translate("pages.devices.noLinkedDevicesTip")}</p>
              </div>
            </div>
          )}
          {linkedDevicesData && linkedDevicesData.length > 0 && (
            <div className="mt-5">
              {linkedDevicesData?.map((dlink) => (
                <div
                  key={dlink.id}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 my-2.5 w-96"
                >
                  <div className="flex flex-row items-center">
                    <div className="flex-shrink-0 w-32 text-center">
                      <DeviceImage type={dlink.type_name} />
                      <p className="py-1">
                        {getConnectionDisplayName(dlink.connection_name)}
                      </p>
                    </div>
                    <div className="flex flex-col p-2.5 items-center w-80">
                      <div className="text-center mb-4">
                        <h3 className="font-medium text-ellipsis overflow-hidden max-w-56" title={dlink.device_name}>
                          {dlink.device_name}
                        </h3>
                        <p className="text-gray-600 truncate">
                          {dlink.type_name}
                        </p>
                        <p className="text-gray-600 truncate">
                          {dlink.model_name}
                        </p>
                      </div>
                      <Button
                        style={ButtonStyle.RedPrimary}
                        name={translate("pages.devices.removeLink")}
                        onClick={() => {
                          setDeviceLinkId(dlink.id)
                          setShowRemoveDeviceLinkModal(true)
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
