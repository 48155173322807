import { ITreeMenu, useMenu, useRouterContext } from "@pankod/refine-core"
import { useDispatch } from "react-redux"
import { LOG, Logger } from "utilities/logger"
import { setDeviceSearch } from "reduxStore/reducers/deviceSearchReducer"

export const AccordionNavItem = ({
  name,
  route,
  icon,
  label,
  ...otherProps
}: ITreeMenu) => {
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()
  const { selectedKey } = useMenu()
  const isSelected = route === selectedKey
  const dispatch = useDispatch()

  // Destructure non-DOM props to prevent them from being passed to the button
  const {
    parentName,
    show,
    list,
    create,
    edit,
    canCreate,
    canEdit,
    canDelete,
    canShow,
    ...domProps
  } = otherProps

  return (
    <button
      onClick={() => {
        if (route?.includes("devices") || route?.includes("displays")) {
          dispatch(setDeviceSearch(""))
        }
        void Logger().log(LOG.CHANGE_PAGE, route)
        push(route)
      }}
      className={`px-2 flex items-center h-10 space-x-2 transform rounded-xl hover:bg-one-gray-200/25 ${
        isSelected ? "text-one-rose-500" : "text-one-gray-600"
      }`}
      {...domProps} // Spread only valid DOM props
    >
      <div className="ml-8 z-0">{label}</div>
    </button>
  )
}
