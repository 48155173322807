import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import {
  ChevronDownIcon,
  ChevronRightIcon,
  LockClosedIcon
} from "@heroicons/react/20/solid"
import { useRouterContext } from "@pankod/refine-core"
import { useRef, useState } from "react"
import { generateIcon } from "resources"

type AccordionProps = {
  title: string
  children?: JSX.Element
  icon: IconDefinition
  locked?: boolean
  loading?: boolean
}

export const Accordion = ({
  title,
  children,
  icon,
  locked = false,
  loading = false
}: AccordionProps) => {
  const [isActive, setIsActive] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  const toggleAccordion = () => {
    setIsActive(!isActive)
  }

  const renderIcon = () => {
    if (loading) {
      return null
    }
    if (locked) {
      return <LockClosedIcon className="text-one-purple-900 mr-1.5 h-3 w-3" />
    }
    if (isActive) {
      return <ChevronDownIcon className="text-one-gray-600 mr-1 h-4 w-4" />
    }
    return <ChevronRightIcon className="text-one-gray-600 mr-1 h-4 w-4" />
  }

  const name = title?.toLowerCase()

  return (
    <div className="">
      <button
        data-testid={name && !locked ? `menu-button-${name}` : undefined}
        onClick={
          locked
            ? () => {
                push(`/${name}`)
              }
            : toggleAccordion
        }
        className={`px-2 flex items-center h-12 w-full text-left space-x-2 rounded-lg ${
          isActive
            ? "bg-white hover:bg-one-gray-50 shadow-sm"
            : "hover:bg-one-gray-200/25"
        }`}
      >
        <div
          className={`flex items-center justify-center w-6 h-6 ${
            isActive ? "text-one-rose-400" : ""
          }`}
        >
          {generateIcon(icon, undefined, isActive ? "" : "gray")}
        </div>

        <div
          className={`flex-grow truncate ${
            isActive ? "text-one-rose-500" : ""
          }`}
        >
          {title}
        </div>

        <div>{renderIcon()}</div>
      </button>

      {isActive && (
        <div ref={contentRef} className="mt-2 mb-4 flex flex-col">
          {children}
        </div>
      )}
    </div>
  )
}
