import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  useApiUrl,
  useCustom,
  useCustomMutation,
  useDelete,
  usePermissions,
  useQueryClient,
  useRouterContext,
  useTranslate,
  useUpdate
} from "@pankod/refine-core"
import { DeviceModelDropdown } from "components/dropdown/DeviceModelDropdown"
import { Button, ButtonStyle } from "components/globals/Button"
import { Input } from "components/globals/Input"
import { ICalendarResource, IDevice, IDeviceSettings } from "interfaces"
import { useState } from "react"
import { ROLE_KEY } from "utilities/constants"
import customToast from "utilities/toastHelper"
import { ResetPincodeModal } from "./ResetPinCodeModal"
import { DeviceSettingInput } from "./DeviceSettingInput"
import { SpacesDropdown } from "pages/spaces/components/SpacesDropdown"
import { UnlinkButton } from "pages/spaces/components/UnlinkButton"
import { SyModal } from "components/new/shared"
import { LOG, Logger } from "utilities/logger"
import { usePostHog } from "posthog-js/react"
import { CalendarProviderDropdownOld } from "components/dropdown/CalendarProviderDropdownOld"

export const GeneralSettingsTab = ({ device }: { device: IDevice }) => {
  const { mutate } = useUpdate()
  const { mutate: customMutation } = useCustomMutation()
  const { mutate: mutateDelete } = useDelete()
  const apiUrl = useApiUrl()
  const { useHistory } = useRouterContext()
  const { push }: { push: (url: string) => void } = useHistory()
  const posthog = usePostHog()

  const translate = useTranslate()

  const userRole = localStorage.getItem(ROLE_KEY)
  const isSuperAdmin = userRole?.includes(
    process.env.REACT_APP_USER_ROLE as string
  )

  const shouldShowPrintingSettings = (deviceModelType: string) => {
    const PRINTING_SETTINGS_DEVICE_TYPES = ["Printer"]
    return PRINTING_SETTINGS_DEVICE_TYPES.includes(deviceModelType)
  }

  const [showPrintingSettings] = useState<boolean>(
    shouldShowPrintingSettings(device.model.type)
  )

  const [deviceName, setDeviceName] = useState(device.name)
  const [deviceModel, setDeviceModel] = useState(device.model.id)
  const [showResetPincodeModal, setShowResetPincodeModal] =
    useState<boolean>(false)
  const queryClient = useQueryClient()

  const { account_id, address } =
    (device.rooms[0]?.resources?.[0] as ICalendarResource) || {}

  const [calendarAccountId, setCalendarAccountId] = useState(account_id ?? 0)
  const [calendarAddress, setCalendarAddress] = useState(address ?? "")

  const [roomId, setRoomId] = useState(device.rooms[0]?.id)
  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const [unlinkMode, setUnlinkMode] = useState(false)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const handleClose = () => {
    setDeleteModalOpen((prev) => !prev)
  }

  const deleteDevice = () => {
    handleRemoveDevice()
    handleClose()
  }

  const { data: permissionsData } = usePermissions<string>()

  const canDelete =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Device.All")

  const { data: deviceSettingData } = useCustom<IDeviceSettings>({
    url: `devices/${device.id}/settings`,
    method: "get"
  })

  const submitDeviceNameAndModel = (e: React.SyntheticEvent) => {
    e.preventDefault()

    onSubmit({
      device_model_id: `${deviceModel}`,
      name: `${deviceName}`
    })
  }

  const unlinkRoomResource = () => {
    customMutation(
      {
        url: `${apiUrl}/devices/${device.id}/room`,
        method: "delete",
        values: {}
      },
      {
        onError: () => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate("resources.device")
            })
          )
        },
        onSuccess: () => {
          void Logger().log(
            LOG.UNLINK_DEVICE as LOG,
            `Room link Id ${roomId} removed from device Id ${device.id}`
          )
          customToast.success(
            translate("notifications.unlinkSuccess", {
              resource: translate("resources.device")
            })
          )
        },
        onSettled: () => {
          void queryClient.resetQueries({
            queryKey: ["device"]
          })
        }
      }
    )
    setUnlinkMode(false)
  }

  const handleRemoveDevice = () => {
    mutateDelete(
      {
        id: device.id,
        resource: `devices`
      },
      {
        onError: (_error, _variables, _context) => {
          customToast.error(
            translate("notifications.deleteError", {
              resource: translate("resources.device")
            })
          )
        },
        onSuccess: (_data, _variables, _context) => {
          customToast.success(
            translate("notifications.deleteSuccess", {
              resource: translate("resources.device")
            })
          )
        },
        onSettled() {
          void queryClient.invalidateQueries(["devicelist"])
          void queryClient.invalidateQueries(["device", device.id])
          device.model.type === "Room Display"
            ? push("../displays")
            : push("../devices")
        }
      }
    )
  }

  const submitRoomResource = () => {
    const body = {
      room_id: roomId
    }
    onSubmit(body)
    setUnlinkMode(false)
  }

  const submitCalendarProvider = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const body =
      calendarAccountId === 0
        ? {
            resource_address: `${calendarAddress}`
          }
        : {
            resource_account_id: `${calendarAccountId}`,
            resource_address: `${calendarAddress}`
          }

    onSubmit(body)
  }

  const onSubmit = (values: object) => {
    mutate(
      {
        resource: `devices`,
        id: device.id,
        values
      },
      {
        onError: (error) => {
          if (Object.hasOwn(values, "room_id")) {
            void Logger().error(LOG.LINK_DEVICE as LOG, `${error.stack}`)
          }
          customToast.error(
            translate("notifications.editError", {
              resource: translate("resources.device")
            })
          )
        },
        onSuccess: () => {
          if (Object.hasOwn(values, "room_id")) {
            void Logger().log(
              LOG.LINK_DEVICE as LOG,
              `Room Id ${roomId} linked to device Id ${device.id}`
            )
          }
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate("resources.device")
            })
          )
        },
        onSettled: () => {
          void queryClient.resetQueries({
            queryKey: ["device"]
          })
        }
      }
    )
  }

  return (
    <div className="flex flex-col gap-y-4 pb-4">
      {showResetPincodeModal && (
        <ResetPincodeModal
          device={device}
          open={showResetPincodeModal}
          onClose={() => setShowResetPincodeModal(false)}
        />
      )}
      <form onSubmit={submitDeviceNameAndModel}>
        <div className="w-120 border rounded-xl shadow h-auto p-6">
          <Input
            label={translate("pages.devices.form.name")}
            labelStyle="font-medium text-lg"
            inputStyle="mt-4"
            id=""
            value={deviceName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDeviceName(e.target.value)
            }
          />
          {isSuperAdmin && (
            <DeviceModelDropdown
              value={deviceModel}
              setState={setDeviceModel}
            />
          )}
          <Button
            className="w-min h-10 mt-4"
            style={ButtonStyle.BlackPrimary}
            type="submit"
            name={translate("buttons.apply")}
          />
        </div>
      </form>
      {device.model.type === "Room Display" &&
        (posthog.isFeatureEnabled("show-spaces") ? (
          <div className="w-120 border rounded-xl shadow h-auto p-6">
            <h1 className="text-lg font-medium mb-4">Linked space</h1>
            <SpacesDropdown
              setSelectedRoomId={setRoomId}
              selectedRoomId={roomId}
            />
            <div className="flex flex-row items-center mt-4">
              <Button
                className="w-min h-10"
                name={translate("buttons.apply")}
                style={ButtonStyle.BlackPrimary}
                onClick={() => {
                  // If there is a room linked to the device, open the modal to unlink it
                  if (device.rooms[0]) {
                    setLinkModalOpen(true)
                  } else if (roomId) {
                    submitRoomResource()
                  }
                }}
              />
              {roomId && device.rooms[0] && (
                <div className="w-32 ml-2">
                  <UnlinkButton
                    onClick={() => {
                      setUnlinkMode(true)
                      setLinkModalOpen(true)
                    }}
                  />
                </div>
              )}
            </div>
            <SyModal
              open={linkModalOpen}
              onClose={() => {
                setUnlinkMode(false)
                setLinkModalOpen(false)
              }}
              title={
                unlinkMode
                  ? translate("pages.spaces.unlinkConfirmation")
                  : translate("pages.spaces.changeLinkTitle")
              }
              size="lg"
            >
              {!unlinkMode && (
                <p className="text-one-gray-500">
                  {translate("pages.spaces.changeLinkDescription")}
                </p>
              )}
              <div className="flex flex-row justify-between gap-4">
                <button
                  className="w-full mt-4 ring-1 ring-one-gray-300 border rounded-lg border-one-gray-400 text-one-gray-900 px-4 py-2 font-medium"
                  onClick={() => {
                    setUnlinkMode(false)
                    setLinkModalOpen(false)
                  }}
                >
                  {translate("buttons.no")}
                </button>
                <button
                  onClick={unlinkMode ? unlinkRoomResource : submitRoomResource}
                  className="w-full mt-4 rounded-lg text-white bg-one-gray-900 px-4 py-2 font-medium"
                >
                  {translate("buttons.yes")}
                </button>
              </div>
            </SyModal>
          </div>
        ) : (
          <div className="w-120 border rounded-xl shadow h-auto p-6">
            <h1 className="text-lg font-medium mb-4">Calendar provider</h1>
            <form onSubmit={submitCalendarProvider}>
              <CalendarProviderDropdownOld
                value={calendarAccountId}
                setState={setCalendarAccountId}
              />
              <div className="mt-4 flex flex-col">
                <Input
                  disabled={calendarAccountId === 0}
                  label={translate("pages.devices.settings.calendarResource")}
                  id="input_address"
                  value={calendarAddress}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCalendarAddress(e.target.value)
                  }
                />
                <p className="text-sm text-one-gray-500 mt-1">
                  {translate("form.resourceAddressDescription")}
                </p>
              </div>
              <Button
                className="w-min h-10 mt-4"
                style={ButtonStyle.BlackPrimary}
                type="submit"
                name={translate("buttons.apply")}
              />
            </form>
          </div>
        ))}
      {(device.model.type === "Kiosk" ||
        device.model.type === "Room Display") && (
        <div className="w-120 border rounded-xl shadow h-auto p-6">
          {device.pin_code && (
            <div className=" flex justify-between items-end">
              <Input
                labelStyle="font-medium text-lg"
                inputStyle="mt-4"
                label={translate("pages.devices.pinCode")}
                id="input_pincode"
                value={device.pin_code}
                disabled={true}
              />
              <button
                className="ml-2 h-12 w-14 ring-1 ring-gray-300 ring-inset rounded-md"
                onClick={(e) => {
                  e.preventDefault()
                  void navigator.clipboard.writeText(device.pin_code)
                  customToast.info(
                    translate("notifications.copiedToClipboard", {
                      resource: translate("pages.devices.pinCode")
                    })
                  )
                }}
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
            </div>
          )}
          {(device.pin_code === undefined ||
            device.pin_code === null ||
            device.pin_code === "") && (
            <div>
              <p className="font-medium text-lg">
                {translate("pages.devices.pinCode")}
              </p>
              <Button
                style={ButtonStyle.PinkPrimary}
                className="mt-4"
                name="Reset pincode"
                onClick={() => {
                  setShowResetPincodeModal(true)
                }}
              />
            </div>
          )}
        </div>
      )}
      {device.model.type === "Kiosk" && (
        <div className="w-120 border rounded-xl shadow h-auto p-6">
          <DeviceSettingInput
            deviceId={device.id}
            deviceSettingsData={deviceSettingData?.data.settings || []}
            settingName="PinCodeSignIn"
            dataType={"Boolean"}
            description={translate(
              "pages.devices.settings.PinCodeSignInDescription"
            )}
          />
        </div>
      )}
      {showPrintingSettings && (
        <div className="w-120 border rounded-xl shadow h-auto p-6">
          <h1 className="text-xl font-medium mb-1">Printing</h1>
          <DeviceSettingInput
            deviceId={device.id}
            deviceSettingsData={deviceSettingData?.data.settings || []}
            settingName="IPAddress"
            dataType={"Text"}
            description={
              "Please enter the IP address of the printer on your network"
            }
          />
        </div>
      )}
      {canDelete && (
        <div className="w-120 border rounded-xl shadow h-auto p-6">
          <div className="flex-col">
            <h2 className="text-lg font-medium leading-6 text-gray-900 mb-1">
              {translate("pages.spaces.dangerZone")}
            </h2>
            <p className="text-sm text-one-gray-500">
              {translate("pages.devices.dangerZoneDescription")}
            </p>
            <button
              onClick={handleClose}
              className="mt-4 ring-1 border rounded-lg border-one-rose-500 text-one-rose-500 px-4 py-2 font-medium"
            >
              {translate("pages.devices.deleteDevice")}
            </button>
          </div>
          <SyModal
            open={deleteModalOpen}
            onClose={handleClose}
            title={translate("pages.devices.deleteConfirmation")}
            size="lg"
          >
            <div className="flex flex-row justify-between gap-4">
              <button
                className="w-full mt-4 ring-1 ring-one-gray-300 border rounded-lg border-one-gray-400 text-one-gray-900 px-4 py-2 font-medium"
                onClick={handleClose}
              >
                {translate("buttons.cancel")}
              </button>
              <button
                onClick={deleteDevice}
                className="w-full mt-4 ring-1 border rounded-lg border-one-rose-500 text-one-rose-500 px-4 py-2 font-medium"
              >
                {translate("pages.devices.deleteDevice")}
              </button>
            </div>
          </SyModal>
        </div>
      )}
    </div>
  )
}
