import { useCustomMutation, useTranslate } from "@pankod/refine-core"
import { Button, ButtonStyle } from "components/globals/Button"
import { Toggler } from "components/globals/Toggler"
import { SyModal } from "components/new/shared"
import { IDeviceSetting } from "interfaces"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { ClipLoader } from "react-spinners"
import { axiosNoUrl, axiosInstance } from "utilities/dataProvider"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import { SETTING_DATA_TYPES } from "utilities/types"

export const DeviceSettingInput = ({
  deviceId,
  deviceSettingsData,
  settingName,
  dataType,
  description
}: {
  deviceId: number
  deviceSettingsData: IDeviceSetting[]
  settingName: string
  // Setting data type can have fixed value or try to use metadata value from setting
  dataType?: SETTING_DATA_TYPES | null
  // Set string to display custom descrption. Null to disable. Otherwise will show description from database.
  description?: string | null
}) => {
  const [settingValue, setSettingValue] = useState("")
  const [settingDataType, setSettingDataType] = useState("")
  const [checked, setChecked] = useState(false)
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  )
  const [imgPreview, setImgPreview] = useState("")
  const [isLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const translate = useTranslate()
  const { mutate: customMutation } = useCustomMutation()

  const usePrevious = (value: string) => {
    const ref = useRef<string>()
    useEffect(() => {
      ref.current = value
    }, [value]) // Only re-run if value changes

    return ref.current
  }

  const selectedSetting = deviceSettingsData?.filter(
    (setting) => setting.setting_metadata.key === settingName
  )[0]

  useEffect(() => {
    if (selectedSetting !== undefined) {
      setSettingValue(selectedSetting.value)
      setSettingDataType(
        dataType ?? selectedSetting.setting_metadata.data_type.name
      )
      if (selectedSetting.setting_metadata.data_type.name === "Boolean") {
        setChecked(selectedSetting.value === "true")
      }
    } else if (dataType != null) {
      setSettingDataType(dataType)
    }
  }, [selectedSetting, dataType])

  const prevSettingValue = usePrevious(settingValue)

  /// Checks if setting has dedicated localization for changing value. Otherwise will use generic localization.
  const checkSettingUpdateLocalization = (
    settingName: string,
    localizationType: string
  ) => {
    const translated = translate(
      `notifications.${settingName}.${localizationType}`
    )
    if (translated === `notifications.${settingName}.${localizationType}`) {
      return translate(`notifications.changeSetting${localizationType}`, {
        resource: translate(`pages.devices.settings.${settingName}`)
      })
    } else {
      return translated
    }
  }

  const updateSetting = (value: string) => {
    if (
      settingName !== "IPAddress" &&
      settingDataType !== "Picture" &&
      value === prevSettingValue
    ) {
      return
    }

    const successText = checkSettingUpdateLocalization(settingName, "Success")
    const errorText = checkSettingUpdateLocalization(settingName, "Error")

    customMutation(
      {
        url: `devices/${deviceId}/settings/${settingName}`,
        values: { value },
        method: "put"
      },
      {
        onSuccess: (_data, _variables, _context) => {
          customToast.success(successText)
        },
        onError: (_error, _variables, _context) => {
          customToast.error(errorText)
          void Logger().error(LOG.EDIT_SETTING, `${_error.stack}`)
        }
      }
    )
  }

  const resetButtonStyle = "px-1 text-soft-blue"

  let inputStyle: string | undefined

  if (settingDataType === "Color") {
    inputStyle =
      "border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-500 focus:border-2 w-8 h-8"
  } else {
    inputStyle =
      "bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:border-2 block p-2.5 w-full"
  }

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const target = event.target
    const file = target.files?.[0]
    setSelectedImage(file)
  }

  useEffect(() => {
    const uploadPhoto = async () => {
      let publicUrl = ""

      const getSignedUrl = async () => {
        if (selectedImage !== undefined) {
          try {
            const res = await axiosInstance.post(
              `/devices/${deviceId}/settings/signed-url`,
              {
                blob: selectedImage?.name
              }
            )
            const signedUrl: string = res.data.signed_url
            publicUrl = res.data.public_url

            void axiosNoUrl.put(signedUrl, selectedImage, {
              headers: {
                "Content-type": selectedImage.type
              }
            })
          } catch (error) {
            customToast.error(translate("notifications.fileUploadError"))
            void Logger().error(LOG.SIGNED_URL, `${error}`)
          }
        }
      }
      await getSignedUrl()
      setSettingValue(publicUrl)
      updateSetting(publicUrl)
    }

    if (selectedImage !== undefined) {
      const objectUrl = URL.createObjectURL(selectedImage)
      setImgPreview(objectUrl)
      void uploadPhoto()

      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [selectedImage])

  return (
    <div>
      {settingDataType === "Boolean" && (
        <Toggler
          header={`pages.devices.settings.${settingName}`}
          description={
            description !== undefined
              ? description
              : selectedSetting?.setting_metadata.description
          }
          checked={checked}
          onChange={() => {
            updateSetting(checked ? "false" : "true")
            setSettingValue(checked ? "false" : "true")
            setChecked(!checked)
          }}
          className="mb-8"
        />
      )}
      {settingDataType === "Picture" && (
        <div>
          <div className="flex flex-col flex-1 mb-2">
            <div className="flex flex-col justify-between mb-1">
              <p className="font-medium self-start mb-2">
                {translate(`pages.devices.settings.${settingName}`)}
              </p>
              <input
                type="file"
                className={inputStyle}
                onChange={(e) => onChangeFile(e)}
              ></input>
            </div>
            <p className="text-gray-500">
              {description ?? selectedSetting?.setting_metadata.description}
            </p>
          </div>
          {settingValue !== "" ? (
            <div className="flex flex-col pt-2 mb-2 items-start">
              <img
                id="preview"
                className="inset-0 object-scale-down w-32"
                alt="Preview"
                src={imgPreview !== "" ? imgPreview : settingValue}
              />
              <Button
                name="Reset"
                style={`${resetButtonStyle}`}
                onClick={() => {
                  setSettingValue("")
                  updateSetting("")
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col pt-2">
              <p>{translate("pages.devices.noImage")}</p>
            </div>
          )}
        </div>
      )}
      {settingDataType === "Color" && (
        <div className="flex flex-row">
          <div className="flex flex-col flex-1 mb-8">
            <div className="flex justify-between mb-3">
              <p className="font-medium self-center">
                {translate(`pages.devices.settings.${settingName}`)}
              </p>
            </div>
            <div className="flex flex-row gap-4 justify-start items-center">
              <input
                type={settingDataType}
                className={inputStyle}
                value={
                  settingDataType === "Color"
                    ? settingValue === ""
                      ? "#548bd3" // Default color on kiosk
                      : settingValue
                    : settingValue
                }
                onChange={(event) => setSettingValue(event.target.value)}
                onBlur={() => updateSetting(settingValue)}
              ></input>
              {settingDataType === "Color" && (
                <Button
                  name="Reset"
                  style={`${resetButtonStyle}`}
                  onClick={() => {
                    setSettingValue("")
                    updateSetting("")
                  }}
                />
              )}
            </div>
            <p className="text-gray-500 mt-2">
              {description ?? selectedSetting?.setting_metadata.description}
            </p>
          </div>
        </div>
      )}
      {settingDataType !== "Boolean" &&
        settingDataType !== "Picture" &&
        settingDataType !== "Color" && (
          <div className="flex flex-col flex-1 mb-8">
            <div className="flex justify-between mb-3">
              <p className="font-medium self-center">
                {translate(`pages.devices.settings.${settingName}`)}
              </p>
            </div>
            <input
              type={settingDataType}
              className={inputStyle}
              value={settingValue}
              onChange={(event) => setSettingValue(event.target.value)} // Keep the same for all settings
              onBlur={() => {
                if (settingName !== "IPAddress") {
                  updateSetting(settingValue) // Trigger update only if not IPAddress
                }
              }}
            />
            <p className="text-gray-500 mt-2">
              {description ?? selectedSetting?.setting_metadata.description}
            </p>

            {settingName === "IPAddress" && (
              <>
                <Button
                  className="w-min h-10 mt-4"
                  style={ButtonStyle.BlackPrimary}
                  name="Apply"
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                />
                <SyModal
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  title="Confirm printer IP address"
                  size="lg"
                >
                  <div className="flex flex-col mt-6 mb-4 items-center">
                    <h1 className="font-medium text-2xl mb-6">
                      {settingValue}
                    </h1>
                    <p className="text-center">
                      Is this the correct IP address for the printer? Do you
                      want to save it?
                    </p>
                    <div className="flex flex-row justify-between gap-4 w-full mt-8">
                      <button
                        className="w-1/2 ring-1 border rounded-lg border-one-rose-500 text-one-rose-500 px-3 py-1 font-medium"
                        onClick={() => setIsModalOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={isLoading}
                        onClick={() => {
                          setIsModalOpen(false)
                          updateSetting(settingValue)
                        }}
                        className={`w-1/2 rounded-lg bg-one-rose-400 shadow-sm text-white px-3 py-1 font-medium hover:bg-one-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-400 ${
                          isLoading ? "cursor-not-allowed" : ""
                        }`}
                      >
                        {isLoading ? (
                          <div className="flex justify-center items-center w-full">
                            <ClipLoader
                              color="#fff"
                              loading={true}
                              size={25}
                              aria-label="Loading Spinner"
                            />
                          </div>
                        ) : (
                          "Apply"
                        )}
                      </button>
                    </div>
                  </div>
                </SyModal>
              </>
            )}
          </div>
        )}
    </div>
  )
}
